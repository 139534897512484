// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

import parseXML from "xml-parse-from-string";

import { library, dom } from "@fortawesome/fontawesome-svg-core";

import { faClock          as fasFaClock          } from "@fortawesome/pro-solid-svg-icons/faClock";
import { faComment        as fasFaComment        } from "@fortawesome/pro-solid-svg-icons/faComment";
import { faFilePdf        as fasFaFilePdf        } from "@fortawesome/pro-solid-svg-icons/faFilePdf";
import { faFolder         as fasFaFolder         } from "@fortawesome/pro-solid-svg-icons/faFolder";
import { faQuestionCircle as fasFaQuestionCircle } from "@fortawesome/pro-solid-svg-icons/faQuestionCircle";
import { faTag            as fasFaTag            } from "@fortawesome/pro-solid-svg-icons/faTag";
import { faUserCircle     as fasFaUserCircle     } from "@fortawesome/pro-solid-svg-icons/faUserCircle";

import { faBars           as farFaBars           } from "@fortawesome/pro-regular-svg-icons/faBars";
import { faSearch         as farFaSearch         } from "@fortawesome/pro-regular-svg-icons/faSearch";
import { faTimes          as farFaTimes          } from "@fortawesome/pro-regular-svg-icons/faTimes";

import { faAngleDown      as falFaAngleDown      } from "@fortawesome/pro-light-svg-icons/faAngleDown";
import { faAngleLeft      as falFaAngleLeft      } from "@fortawesome/pro-light-svg-icons/faAngleLeft";
import { faAngleRight     as falFaAngleRight     } from "@fortawesome/pro-light-svg-icons/faAngleRight";
import { faAngleUp        as falFaAngleUp        } from "@fortawesome/pro-light-svg-icons/faAngleUp";

import { faFacebookF      as fabFaFacebookF      } from "@fortawesome/free-brands-svg-icons/faFacebookF";
import { faLinkedinIn     as fabFaLinkedinIn     } from "@fortawesome/free-brands-svg-icons/faLinkedinIn";
import { faTwitter        as fabFaTwitter        } from "@fortawesome/free-brands-svg-icons/faTwitter";
import { faYoutube        as fabFaYoutube        } from "@fortawesome/free-brands-svg-icons/faYoutube";

import facListCheck             from "../../media/fac-list-check.svg";
import facMagnifyingGlassGraph  from "../../media/fac-magnifying-glass-graph.svg";
import facPeopleArrowsLeftRight from "../../media/fac-people-arrows-left-right.svg";
import facPersonChalkboard      from "../../media/fac-person-chalkboard.svg";
import facPersonStairsFlag      from "../../media/fac-person-stairs-flag.svg";

/**
 * Add icons
 */
library.add(fasFaClock, fasFaComment, fasFaFilePdf, fasFaFolder, fasFaQuestionCircle, fasFaTag, fasFaUserCircle, farFaBars, farFaSearch, farFaTimes, falFaAngleDown, falFaAngleLeft, falFaAngleRight, falFaAngleUp, fabFaFacebookF, fabFaLinkedinIn, fabFaTwitter, fabFaYoutube);


/**
 * Add custom icons
 */
library.add({
    "list-check": {
        iconName: "list-check",
        prefix: "fac",
        icon: [
            facListCheck.attributes.viewBox.split(" ")[2],
            facListCheck.attributes.viewBox.split(" ")[3],
            [],
            "e000",
            parseXML(facListCheck.content).firstElementChild.getAttribute("d"),
        ]
    },
    "magnifying-glass-graph": {
        iconName: "magnifying-glass-graph",
        prefix: "fac",
        icon: [
            facMagnifyingGlassGraph.attributes.viewBox.split(" ")[2],
            facMagnifyingGlassGraph.attributes.viewBox.split(" ")[3],
            [],
            "e001",
            parseXML(facMagnifyingGlassGraph.content).firstElementChild.getAttribute("d"),
        ]
    },
    "people-arrows-left-right": {
        iconName: "people-arrows-left-right",
        prefix: "fac",
        icon: [
            facPeopleArrowsLeftRight.attributes.viewBox.split(" ")[2],
            facPeopleArrowsLeftRight.attributes.viewBox.split(" ")[3],
            [],
            "e002",
            parseXML(facPeopleArrowsLeftRight.content).firstElementChild.getAttribute("d"),
        ]
    },
    "person-chalkboard": {
        iconName: "person-chalkboard",
        prefix: "fac",
        icon: [
            facPersonChalkboard.attributes.viewBox.split(" ")[2],
            facPersonChalkboard.attributes.viewBox.split(" ")[3],
            [],
            "e003",
            parseXML(facPersonChalkboard.content).firstElementChild.getAttribute("d"),
        ]
    },
    "person-stairs-flag": {
        iconName: "person-stairs-flag",
        prefix: "fac",
        icon: [
            facPersonStairsFlag.attributes.viewBox.split(" ")[2],
            facPersonStairsFlag.attributes.viewBox.split(" ")[3],
            [],
            "e004",
            parseXML(facPersonStairsFlag.content).firstElementChild.getAttribute("d"),
        ]
    },
});

/**
 * Watch the DOM to insert icons
 */
dom.watch();
